export const strict = false
import marks from '~/apollo/queries/marks'
import settings from '~/apollo/queries/settings'
import getMarkFolders from '~/apollo/queries/getMarkFolders'
import { currentDomain } from "~/app/variables";

export const state = () => ({
  site_id: null,
  domain: '',
})
export const getters = {
  site_id: (state) => {
    return state.site_id
  },
  // isMoscow:(state) =>{
  //    return state.site_id === 21 || state.site_id === 28
  // },
  domain: (state) => {
    return state.domain
  },
}
export const actions = {
  async nuxtServerInit({ dispatch, commit, state }, { req, app, $config }) {
    const site = currentDomain(req.headers.host);
    commit('SET_SITE_ID', site.id);
    commit('SET_DOMAIN', site.domain);

    let client = app.apolloProvider.defaultClient
    // TODO получаю дефолтный процент тут так как в state нет экземпляра контекста
    commit('banks/SET_PERCENT', "10%")
    // // TODO танцы с бубнами
    // commit('filters/filters/SET_LOADING_RANGE', false)


    //TODO НАСТРОЙКИ САЙТА
    let currentSettings = {}
    let settingsResponse = await client.query(
      {
        query: settings,
        variables: { site_id: state.site_id }
      })
    settingsResponse.data.settings.settings.map(value => {
      currentSettings[value.key] = value.value
    })
    commit('settings/settings/SET_SETTINGS', currentSettings)

    const utm = Object.fromEntries(new URLSearchParams(req._parsedUrl.query).entries());
    if (utm && utm.utm_campaign && (utm.utm_campaign === 'telegaexp' || utm.utm_campaign === 'telegram' || utm.utm_medium === 'cpc' || utm.utm_source === 'telegram2') && currentSettings.phone_telegram) {
      currentSettings.phone = currentSettings.phone1 = currentSettings.phone_telegram;
    }
    commit('settings/settings/SET_SETTINGS', currentSettings);

    // TODO ПОЛУЧАЮ СПИСОК МАРОК
    let response = await client.query(
      {
        query: getMarkFolders,
        variables: { site_id: state.site_id, category: 'cars' }
      })
    commit('marks/marks/SET_ALL_MARKS', response.data.markFolderGeneration)

    let responseNew = await client.query(
      {
        query: getMarkFolders,
        variables: { site_id: state.site_id, category: 'new' }
      })
    commit('marks/marks/SET_ALL_MARKS_NEW', responseNew.data.markFolderGeneration)

  },
  async request({ state }, { query, variables }) {
    let assignVariables = {
      site_id: state.site_id
    }
    let client = this.app.apolloProvider.defaultClient
    let params = { ...assignVariables, ...variables }
    return await client.query(
      {
        query: query,
        variables: this.$removeEmptyObjects(params),
        fetchPolicy: 'no-cache'
      })
  }
}
export const mutations = {
  SET_SITE_ID(state, data) {
    state.site_id = data
  },
  SET_DOMAIN(state, data) {
    state.domain = data
  },
}
